<template>
    <div class="ui-tags">
        <div
            class="ui-tags__container"
            :class="{
                'ui-tags__container--valid': field && field.$dirty && !field.$error,
                'ui-tags__container--invalid': field && field.$dirty && field.$error,
            }"
        >
            <label :for="id" class="ui-tags__label">
                {{ label }}
            </label>
            <div class="ui-tags__content">
                <template v-if="tags.length">
                    <span v-for="tag in tags" :key="tag" class="ui-tags__tag">
                        {{ tag }}
                        <button type="button" class="ui-tags__remove-tag-button" @click="removeTag(tag)">
                            <span class="fas fa-times"></span>
                        </button>
                    </span>
                </template>
                <input
                    :id="id"
                    :name="name"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    class="ui-tags__input"
                    novalidate="true"
                    v-model="newTag"
                    @keydown.backspace="removeLastTag"
                    @keydown.,.prevent="addTag"
                    @keydown.enter.prevent="addTag"
                    @keydown.space.prevent="addTag"
                    @keydown.tab.prevent="addTag"
                    @blur="addTag"
                />
            </div>
            <div v-if="v$.newTag.$error" class="w-100">
                <small class="text-danger">
                    <i class="fas fa-info-circle"></i> {{ v$.newTag.$errors[0].$message }}
                </small>
            </div>
        </div>
        <div v-if="field && field.$error" class="ui-tags__error">
            {{ field.$errors[0].$message }}
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';

export default {
    name: 'UiTags',
    props: {
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        field: {
            type: Object,
            default: null,
            required: false,
        },
        id: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            default: 'separated by space, comma or semicolon',
            required: false,
        },
        tagValidations: {
            type: Object,
            default: () => ({}),
            required: false,
        },
        value: {
            type: Array,
            default: () => ([]),
            required: true,
        },
    },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            tags: [],
            newTag: '',
        };
    },
    validations() {
        return {
            newTag: this.tagValidations,
        };
    },
    methods: {
        removeLastTag() {
            if (!this.newTag) {
                this.tags.pop();

                this.$emit('update:value', this.tags);
            }
        },
        removeTag(tag) {
            this.tags = this.tags.filter(item => item !== tag);
            this.$emit('update:value', this.tags);
        },
        async addTag() {
            if (!await this.v$.$validate() || !this.newTag) {
                return;
            }

            if (!this.tags.some(tag => tag === this.newTag)) {
                this.tags.push(this.newTag);
                this.$emit('update:value', this.tags);
            }

            this.newTag = '';
        }
    },
};
</script>

<style lang="scss">
.ui-tags {
    position: relative;

    &__container {
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        width: 100%;

        padding: 4.5px 8px;
        // padding-top: 15px;

        &:focus-within {
            border-color: $ecart-secondary-200;
            box-shadow: none;
        }

        &--valid {
            border-color: $general-success;

            &:focus-within {
                border-color: $general-success;
                box-shadow: none;
            }
        }

        &--invalid {
            border-color: $general-error;

            &:focus-within {
                border-color: $general-error;
                box-shadow: none;
            }
        }
    }

    &__content {

        display: flex;
        flex-wrap: wrap;
        gap: 4px;
    }

    &__tag {
        background-color: $ecart-secondary-100;
        border-radius: 5px;
        padding: 2px 8px;
    }

    &__remove-tag-button {
        background-color: transparent;
        border: 0;
        color: $ecart-secondary-500;
        font-size: 0.8rem;
        padding: 0;
    }

    &__label {
        margin-bottom: 0;
        font-weight: 400;
        line-height: 1;
        // position: absolute;
        // left: 8px;
        // transition: 200ms all;

        color: $general-black;
        font-size: 12px;
        // top: 4px;
    }

    &__input {
        background-color: transparent;
        background-image: none;
        border: none;
        border-radius: 8px;
        color: $general-black;
        flex-grow: 1;
        font-size: 14px;
        font-weight: 500;
        height: 100%;
        line-height: 1;
        overflow: hidden;
        padding: 4.5px 0;
        text-overflow: ellipsis;
        white-space: nowrap;


        &::placeholder {
            color: $ecart-secondary-400;
            font-weight: 400;
        }

        &:focus {
            background-color: transparent;
        }

        &:disabled {
            color: $ecart-secondary-300;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active  {
            -webkit-background-clip: text;
        }
    }

    &__error {
        color: $general-error;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}
</style>
