<template>
    <div class="admin-invite-page">
        <router-link to="/admins" class="btn btn-light mb-3">
            <span class="fas fa-chevron-left mr-1"></span>
            Back
        </router-link>
        <div class="row">
            <div>
                <ui-card title="Invitation form" description="Enter the information requested below.">
                    <form id="form-cards-add" @submit.prevent="sendInvitations">
                        <invitation-admin-form ref="invitationAdminForm" :profiles="profiles" />
                        <div class="text-end">
                            <ui-button
                                type="submit"
                                variant="primary"
                                :loading="sendingInvitations"
                            >
                                Send invitations
                            </ui-button>
                        </div>
                    </form>
                </ui-card>
            </div>
        </div>
    </div>
</template>

<script>
import InvitationAdminForm from '@/components/admins/InvitationForm.vue';
import UiButton from '@/components/ui/buttons/Button'
import UiCard from '@/components/ui/Card.vue';

export default {
    components: {
        InvitationAdminForm,
        UiButton,
        UiCard,
    },
    data() {
        return {
            sendingInvitations: false,
            profiles: [],
        };
    },
    methods: {
        async sendInvitations() {
            try {
                const invitationAdminForm = this.$refs['invitationAdminForm']

                if (!await invitationAdminForm.validate()) {
                    const error = invitationAdminForm.v$.$errors[0];
                    const element = document.getElementById(error.$property);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    } else {
                        this.$toast.error("Please fill in all required fields.");
                    }
                    return;
                }
                
                this.sendingInvitations = true;

                const invitations = [];
                let { emails, role, scopes, profile } = invitationAdminForm.formData;
                const profiles_info = invitationAdminForm.profiles_info;
                profile = profiles_info.find(p => {
                    if (p.id === profile) {
                        return p.name;
                    }
                });
                emails.forEach(email => {
                    invitations.push({
                        type: 'signup',
                        email,
                        role,
                        scopes,
                        profile: profile?.name,
                    });
                });

                await this.$axios.post('/_/invitations', invitations);
                this.$router.push('/admins')
                this.$toast.success('Invitations sent successfully.');
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                this.sendingInvitations = false;
            }
        }
    },
};
</script>