<template>
    <div class="row">
        <div class="col-md-12 mb-4">
            <ui-tags
                id="emails"
                name="emails"
                label="Email list"
                placeholder="Enter new emails separated by space, comma or semicolon"
                v-model:value="formData.emails"
                :field="v$.formData.emails"
                :tag-validations="tagValidations"
            />
        </div>
        <div class="col-md-6 mb-4">
            <ui-select
                id="role"
                name="role"
                class="test-class w-100"
                label="Role"
                option-label="key"
                option-value="value"
                v-model:value="formData.role"
                :options="roles"
                :field="v$.formData.role"
            />
        </div> 
        <div class="col-md-6 mb-4">
            <ui-select
                id="profile"
                name="profile"
                class="test-class w-100"
                label="Profile"
                option-label="key"
                option-value="value"
                v-model:value="formData.profile"
                :options="profiles"
                :disabled="formData.role && profiles?.length > 0 ? false : true"
                :field="v$.formData.profile"
            />
        </div>
        <div class="admin-scopes__subtitle m-1">Scopes</div>
        <p class="admin-scopes__description m-1 mb-3"> Select the scopes that will be assigned to the new admin </p>
        <scope-list id="scopes" @selectedScopes="handleScopes" :preSelectedScopes="scopes"/>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength, helpers } from '@vuelidate/validators';

import UiTags from '@/components/ui/Tags.vue';
import UiSelect from '@/components/ui/Select.vue';
import ScopeList from '@/components/admins/scopes/ScopesList.vue';

export default {
    name: 'InvitationAdminForm',
    components: { UiTags, UiSelect, ScopeList },
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            formData: {
                emails: [],
                role: '',
                profile: '',
            },
            roles: [
                {
                    key: 'Admin',
                    value: 'admin',
                },
                {
                    key: 'User',
                    value: 'user',
                },
            ],
            tagValidations: {
                email: helpers.withMessage('Enter a valid email', email)
            },
            profiles: [],
            scopes: [],
            profiles_info: [],
        };
    },
    validations() {
        return {
            formData: {
                emails: {
                    required,
                    minLength: minLength(1),
                    $autoDirty: true,
                },
                role: {
                    required,
                    $autoDirty: true,
                },
                profile: {
                    optional: true,
                    $autoDirty: true,
                },
                scopes: {
                    required,
                    minLength: minLength(1),
                    $autoDirty: true,
                },
            }
        };
    },
    watch: {
        'formData.role': {
            handler() {
                this.$nextTick(() => {
                    this.loadProfiles();
                });
            },
            deep: true,
            lazy: true,
        },
        'formData.profile': {
            handler() {
                this.loadScopes();
            },
            deep: true,
            lazy: true,
        }
    },
    mounted() {
        this.getProfiles();
    },
    methods: {
        async validate() {
           return await this.v$.$validate();
        },
        async getProfiles() {
            const { data } = await this.$axios.get('/_/profiles/filters');
            const { docs } = data;
            this.profiles_info = docs;
        },
        async loadProfiles() {
            if (this.formData.role) {
                this.profiles = this.profiles_info.map((profile) => {
                    if (profile.role === this.formData.role) {
                        return {
                            key: profile.name,
                            value: profile.id,
                        };
                    } else {
                        return null;
                    }
                }).filter((profile) => profile !== null);
            } else {
                this.profiles = [];
            }
        },
        handleScopes(scopes) {
            this.formData.scopes = scopes;
        },
        async loadScopes() {
            if (this.formData.role && this.formData.profile) {
                const profile = this.profiles_info.find((profile) => profile.id === this.formData.profile);
                this.scopes = profile?.scopes || [];
            } else {
                this.scopes = [];
            }
        }
    },
}
</script>

<style lang="scss">
.admin-scopes {
    &__actions {
        align-items: center;
        display: flex;
        margin-left: au;
    }

    &__column {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        max-width: 100%;
        > div {
            flex-basis: calc(33.33% - 10px);
            margin-bottom: 10px;
        }
    }

    &__title {
        color: $ecart-primary-400;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 1rem;
    }

    &__subtitle {
        color: $general-black;
        font-size: 15px;
        font-weight: 600;
    }

    &__description {
        color: #7B7B7B;
        font-weight: 400;
        margin-bottom: 0;
        margin-left: 0.2rem;
    }
}
</style>